import { inputsChange } from './components/inputsChange';
import { handlingUpload, handlingRequestCall } from "./components/handlers";
import { plusSvg, uploadImage } from './components/templates';
import { magnificPopupTrigger } from './components/magnificPopupTrigger';

$(function() {
    const $form = $('.js-form');
    const $fileInput = $('.js-file-input');
    const $uploadMaxSizeAlert = $(".js-upload-max");
    const $uploadBtn = $(".js-upload-btn");
    const $uploadFiles = $(".js-upload-files");
    const $uploadAttached = $(".js-upload-attached");
    const $uploadError = $(".js-upload-error");
    const $body = $("body");

    const reader = new FileReader();

    $("[type='tel']").mask("+7 (999) 999-99-99", {
        completed: function() {
            $(this).removeClass('error')
        }
    });

    $(".js-form-select").select2({
        language: {
            noResults: function () {
                return "Ничего не найдено";
            },
        },
        placeholder: "Выберите модель"
    })

    const cloneDefaultStateUploadBtn = $uploadBtn.children().clone();

    let filesArr = [];

    reader.onload = () => $uploadFiles.append($(uploadImage(reader.result)))

    $body.on("click", ".js-files-clear", function(e) {
        const $this = $(this)
        const idx = $(".js-files-clear").index($this)
        $this.parent().remove()
        $fileInput.val('')
        filesArr.splice(+idx, 1)

        if(!filesArr.length) {
            $uploadAttached.fadeOut(0)
            $uploadBtn.html(cloneDefaultStateUploadBtn).removeClass("setter").next().fadeIn()
        }
    })

    $fileInput.on('change', (e)=> {
        const target = e.target
        const files = target.files
        const size = files[0].size;
        $uploadError.fadeOut()
        if(size > 10000000) return $uploadMaxSizeAlert.addClass("error")
        $uploadMaxSizeAlert.removeClass("error")
        if(files.length) {
            $uploadAttached.fadeIn()
            $uploadBtn.html(plusSvg).addClass("setter").next().fadeOut()
            reader.readAsDataURL(files[0])
            filesArr.push(files[0])
        }
    })

    let formValue = null

    $form.on('submit', function(e) {
        e.preventDefault()
        const target = e.target
        const url = target.action
        const formInput = $(this).find('.js-form-input')
        const formBtn = $(this).find('.js-form-submit')

        const policy = $(this).find('.js-policy')
        inputsChange(formInput)

        const formDataEntries = new FormData(target)
        if(target.dataset.id === 'form-upload') formValue = handlingUpload(formDataEntries, formInput, filesArr, $uploadError)
        if(target.dataset.id === 'form-signup-service') formValue = handlingRequestCall(formDataEntries, formInput)

        if(policy.val() && !policy.is(":checked")) {
            policy.parent().addClass("error")
            policy.on("change", (e)=> $(e.target).parent().removeClass("error"))
            return
        }

        if(formValue === "error") return

        formBtn.attr('disabled', true)
        formInput.addClass('disabled')

        //console.log(formValue);
        //console.log(filesArr);

        fetch(url, {
            method: 'POST',
            body: target.dataset.id === 'form-upload' ? newFormData(formValue, filesArr) : JSON.stringify(formValue)
        })
        .then(()=> {
            target.reset()
            formInput.blur()
            formInput.removeClass('disabled')
            formBtn.removeAttr('disabled')
            magnificPopupTrigger()
            $(".js-files-clear").parent().remove()
            $uploadAttached.fadeOut(0)
            $uploadBtn.html(cloneDefaultStateUploadBtn).removeClass("setter").next().fadeIn()
        }).catch(()=> {
            formInput.removeClass('disabled')
            formBtn.removeAttr('disabled')
        })
    })
})

function newFormData(formValue, filesArr) {
    const formDataEntries = new FormData()
    Object.keys(formValue).forEach(item => formDataEntries.append(item, formValue[item]))
    filesArr.forEach((item, i)=> formDataEntries.append("file-"+i, filesArr[i]))
    return formDataEntries
}
