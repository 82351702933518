$(function() {
    $(document).on('click', '.js-anchor', function (event) {
        event.preventDefault();

        if($(this).hasClass('navigation__nav-link')) {
            $('.navigation__nav-link').removeClass('active');
            $(this).addClass('active');
        }
    
        $('html, body').stop(true, true).delay(200).animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);   
    });
})