$(function() {
    const $paintinDetail = $(".js-paintin-detail");
    const $paintinDot = $(".js-paintin-dot");
    const $paintinCost = $(".js-painting-cost");
    
    const removeActiveDetail = () => $paintinDetail.removeClass("active")

    const removeOpacityDot = () => $paintinDot.removeClass("opacity")

    const iterator = (element, detail, method, className) => {
        if(method === "removeClass") element.addClass(className)
        element.each(function() {
            const data = $(this).attr("data-detail")
            if(detail === data) $(this)[method](className)
        })
    }

    const fixedDetail = (element, target) => {
        target.toggleClass("fixed")
        const detail = target.attr("data-detail")
        iterator(element, detail, "toggleClass", "fixed")
    }

    $paintinDetail.on("click", function() { fixedDetail($paintinDot, $(this)) })
    $paintinDot.on("click", function() { fixedDetail($paintinDetail, $(this)) })

    $paintinDetail.hover(function() {
        const $this = $(this)
        const detail = $this.attr("data-detail")
        removeActiveDetail()
        $this.addClass("active")
        iterator($paintinDot, detail, "removeClass", "opacity")
    })

    $paintinDot.hover(function() {
        const $this = $(this)
        $paintinDot.addClass("opacity")
        $this.removeClass("opacity")
        const detail = $this.attr("data-detail")
        removeActiveDetail()
        iterator($paintinDetail, detail, "addClass", "active")
    })

    $(window).hover(function(e) {
        if(!$paintinCost.is(e.target) && $paintinCost.has(e.target).length === 0) {
            removeActiveDetail()
            removeOpacityDot()
        }
    })
})