export const pointer = [
    {
        hintContent: "Kia",
        icon: "/dist/img//svg/kia.svg",
        name: "Kia КЛЮЧАВТО Омск",
        phone: "+7 (3812) 991719",
        time: "ежедневно с 8:00 до 20:00",
        address: "Омск, бул. Архитекторов, 20/1",
        coor: [54.97557406871744, 73.28438425793134],
        pin: "/dist/img//content/pin2.png",
        route: "https://yandex.com.tr/harita/org/kia_keyauto_official_dealer_of_kia/224120126367/?ll=73.338939%2C54.961555&mode=search&sll=28.780466%2C41.017851&sspn=0.180588%2C0.075800&text=Kia%20%D0%9A%D0%9B%D0%AE%D0%A7%D0%90%D0%92%D0%A2%D0%9E%20%D0%9E%D0%BC%D1%81%D0%BA&z=12",
    },
    {
        hintContent: "Nissan",
        icon: "/dist/img//svg/nissan.svg",
        name: "Nissan КЛЮЧАВТО Омск",
        phone: "+7 (3812) 992657",
        address: "Омск, Волгоградская улица, 63",
        time: "ежедневно с 8:00 до 20:00",
        coor: [54.9887323673069, 73.23473825566462],
        pin: "/dist/img//content/pin1.png",
        route: "https://yandex.com.tr/harita/org/nissan_keyauto/4301515606/?ll=73.338939%2C54.961555&mode=search&sll=28.780466%2C41.017851&sspn=0.180588%2C0.075800&text=Kia%20%D0%9A%D0%9B%D0%AE%D0%A7%D0%90%D0%92%D0%A2%D0%9E%20%D0%9E%D0%BC%D1%81%D0%BA&z=12",
    },
]