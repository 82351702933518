import { validate, validateEmail } from "./validation";

export const errorUpload = (car_model, name, phone, formInput) => {
    const car_modelError = validate(car_model);
    const nameError = validate(name);
    const phoneError = validate(phone);

    formInput.eq(0).focus()
    if (!car_modelError) formInput.eq(1).addClass('error')
    if (!nameError) formInput.eq(2).addClass('error')
    if (!phoneError) formInput.eq(3).addClass('error')
    if (!car_modelError && !nameError && !phoneError ) return false;
    if (car_modelError && nameError && phoneError) return true;
}

export const errorRequestCall = (name, phone, formInput) => {
    const nameError = validate(name);
    const phoneError = validate(phone);
    formInput.eq(0).focus()
    if (!nameError) formInput.eq(0).addClass('error')
    if (!phoneError) formInput.eq(1).addClass('error')
    if (!nameError && !phoneError) return false;
    if (nameError && phoneError) return true;
}