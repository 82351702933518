const phoneReplace = string => string.replace(/[^\d\+]/g, '');

export const balloonModalTemplate = (data, idx) => [
    `<address class="map__address">
        <span class="map__address-close js-address-close" data-idx="${idx}">
            <img src="/dist/img//svg/close.svg" />
        </span>
        <span class="map__address-img">
            <img src="${data.icon}"/>
        </span>
        <span class="map__address-text">${data.name}</span>
        <a href="tel:${phoneReplace(data.phone)}" class="map__address-phone">${data.phone}</a>
        <span class="map__address-time">${data.time}</span>
        <span class="map__address-text">${data.address}</span>
        <a href="${data.route}" target="_blank" class="map__address-route">
            <img src="/dist/img//svg/microPin.svg"/>
            Построить маршрут
        </a>
    </address>`
].join("")