import { errorUpload, errorRequestCall } from "./errorMessages";
import { phoneReplace } from "./replaces";

export const handlingUpload = (formDataEntries, formInput, files, $uploadError) => {
    const { car_model, name, phone } = Object.fromEntries(formDataEntries.entries());
    if(!errorUpload(car_model, name, phone, formInput) || !files?.length) {
        if(!files?.length) $uploadError.fadeIn()
        return "error"
    }
    return {
        car_model,
        name,
        phone: phoneReplace(phone),
    }
}

export const handlingRequestCall = (formDataEntries, formInput) => {
    const { name, phone } = Object.fromEntries(formDataEntries.entries());
    if(!errorRequestCall(name, phone, formInput)) return "error"
    return {
        name,
        phone: phoneReplace(phone),
    }
}