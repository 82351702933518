$(function() {
    const $serviceBtn = $(".js-service__btn");
    const $serviceItem = $(".js-service__item");
    const plus = `<use xlink:href="/dist/img//sprites/sprite.svg#plus"></use>`
    const minus = ` <use xlink:href="/dist/img//sprites/sprite.svg#minus"></use>`

    const resetState = () => {
        $serviceItem.removeClass("show")
        $serviceBtn.removeClass("active").find("svg").html(plus)
    }

    $serviceBtn.on("click", function() {
        const $this = $(this);
        const idx = $serviceBtn.index($this)
        if (!$(this).hasClass('active')) {
            resetState()
        }
        $this.toggleClass("active").find("svg").html(minus)
        $serviceItem.eq(idx).toggleClass("show")
    })

    $serviceItem.on("click", function() {
        if(!$(this).hasClass('show')) resetState()
    })

    $(window).on("click", function(e) {
        if(!$serviceItem.is(e.target) && $serviceItem.has(e.target).length === 0) resetState()
    })
})
