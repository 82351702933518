export const plusSvg = `
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M9 1V9M9 17V9M9 9H1M9 9H17" stroke="#A50034" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`

export const uploadImage = (result) => `
    <div class="upload__files-item">
        <img src="${result}"/>

        <div class="upload__files-clear js-files-clear">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 0.833008C7.8731 0.833008 7.5 1.2061 7.5 1.66634V3.33301H3.33333C2.8731 3.33301 2.5 3.7061 2.5 4.16634C2.5 4.62658 2.8731 4.99967 3.33333 4.99967H16.6667C17.1269 4.99967 17.5 4.62658 17.5 4.16634C17.5 3.7061 17.1269 3.33301 16.6667 3.33301H12.5V1.66634C12.5 1.2061 12.1269 0.833008 11.6667 0.833008H8.33333ZM10.8333 3.33301H9.16667V2.49967H10.8333V3.33301Z" fill="white"/>
                <path d="M8.33333 9.16634C8.79357 9.16634 9.16667 9.53944 9.16667 9.99967V14.9997C9.16667 15.4599 8.79357 15.833 8.33333 15.833C7.8731 15.833 7.5 15.4599 7.5 14.9997V9.99967C7.5 9.53944 7.8731 9.16634 8.33333 9.16634Z" fill="white"/>
                <path d="M12.5 9.99967C12.5 9.53944 12.1269 9.16634 11.6667 9.16634C11.2064 9.16634 10.8333 9.53944 10.8333 9.99967V14.9997C10.8333 15.4599 11.2064 15.833 11.6667 15.833C12.1269 15.833 12.5 15.4599 12.5 14.9997V9.99967Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16667 5.83301C3.70643 5.83301 3.33333 6.2061 3.33333 6.66634V16.6663C3.33333 18.0471 4.45262 19.1663 5.83333 19.1663H14.1667C15.5474 19.1663 16.6667 18.0471 16.6667 16.6663V6.66634C16.6667 6.2061 16.2936 5.83301 15.8333 5.83301H4.16667ZM5 16.6663V7.49967H15V16.6663C15 17.1266 14.6269 17.4997 14.1667 17.4997H5.83333C5.3731 17.4997 5 17.1266 5 16.6663Z" fill="white"/>
            </svg>
        </div>
    </div>
`;