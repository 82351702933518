import { balloonModalTemplate } from "./balloonModalTemplate";
import { pointer } from "./placemarker";

$(function() {
    const $mapSelect = $('.js-map-select');

    ymaps.ready(init); 
	let newMap;
	
    let place;
    let placMarkers = []

    const removeActive = () =>  $mapSelect.removeClass("active");

    $('body').on("click", ".js-address-close", function() {
        const idx = $(this).attr("data-idx")
        removeActive()
        placMarkers[+idx].balloon.close()
    })
    
	function init() {
		newMap = new ymaps.Map("ymaps", {
            center: [54.985609786258614, 73.25038570996264],
            zoom: 12,
            controls: []
		});
        pointer.forEach((item, i)=> {
            place = new ymaps.Placemark(item.coor, {
                hintContent: item.hintContent,
                balloonContentBody: balloonModalTemplate(item, i)
            }, {
                iconLayout: 'default#image',
                iconImageHref: item.pin,
                iconImageSize: [60, 76],
            })

			newMap.geoObjects.add(place);
            
            placMarkers.push(place)

            place.events.add('click', function (event) {
                const hint = event.get('target').properties._data.hintContent
                removeActive()
                $mapSelect.each(function(i) {
                    const content = $(this).attr('data-content')
                    if(hint === content)  $(this).addClass('active');
                })
            })

            
        })

        $mapSelect.on('click', function() {
            removeActive()
            $(this).addClass("active")
            const idx = $(this).attr('data-idx');
            
            placMarkers[+idx].balloon.open()
        })

        const width = $(window).width()
        
        if(width < 770) newMap.behaviors.disable('drag');
	};
})
