$(function() {
    const $carousel = $(".js-carousel");

    $.each($carousel, function(i) {
        $carousel.eq(i).slick({
            slidesToShow: 3,
            prevArrow: $(".js-carousel-prev").eq(i),
            nextArrow: $(".js-carousel-next").eq(i),
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },

                {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      dots: true,
                    },
                },
            ],
        })
    })
})